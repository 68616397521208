<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-0">
        <div class="row justify-content-around bg-current px-2">
            <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-md d-block text-white"><b>Create WIN Analysis</b></h2></div>
        </div>
        <div class="card-body p-2">
            <form @submit.prevent="postWa" class="row p-2">
                <div class="col-12 form-group mb-3">
                    <label for="">Title</label>
                    <input type="text" v-model="wa.title" required placeholder="Enter your WIN Analysis title" class="form-control text-grey-900 font-xsss fw-600">                        
                </div>
                <div class="col-12 form-group mb-3">
                    <label for="">Description</label>
                    <textarea rows="1" v-model="wa.description" required placeholder="Enter your WIN Analysis description" class="form-control text-grey-900 font-xsss fw-600"></textarea>
                </div>
                <div class="col-12 form-group mb-3">
                    <label for="">Time in Minutes</label>
                    <input type="number" required v-model="wa.time" min="0" placeholder="Enter time minutes" class="form-control text-grey-900 font-xsss fw-600">
                </div>
                <div class="col-12 form-group mb-3">
                    <hr>
                </div>
                <div class="col-12 form-group text-right my-4">
                    <router-link :to="{name: 'SettingsAdmin', hash: '#WA'}" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                    <input type="Submit" class="btn btn-current" value="Submit">
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            wa: {
                title:'',
                description: '',
                time: '',
            }
        }
    },
    methods:{
        async postWa() {
            var data = {
                title: this.wa.title,
                description: this.wa.description,
                time: this.wa.time,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/getting-start/wa`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'WIN Analysis!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'SettingsAdmin', hash: '#WA' })
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'WIN Analysis!',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },

    }
}
</script>
